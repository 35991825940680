define(["dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/2f0b59314505d8118ca237515dd06e3befeb5993/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"
/*=====,
'./_base/declare',
'./promise/Promise' =====*/
], function (request /*=====, declare, Promise =====*/) {
  /*=====
  request = function(url, options){
  	// summary:
  	//		Send a request using the default transport for the current platform.
  	// url: String
  	//		The URL to request.
  	// options: dojo/request.__Options?
  	//		Options for the request.
  	// returns: dojo/request.__Promise
  };
  request.__Promise = declare(Promise, {
  	// response: dojo/promise/Promise
  	//		A promise resolving to an object representing
  	//		the response from the server.
  });
  request.__BaseOptions = declare(null, {
  	// query: String|Object?
  	//		Query parameters to append to the URL.
  	// data: String|Object?
  	//		Data to transfer.  This is ignored for GET and DELETE
  	//		requests.
  	// preventCache: Boolean?
  	//		Whether to append a cache-busting parameter to the URL.
  	// timeout: Integer?
  	//		Milliseconds to wait for the response.  If this time
  	//		passes, the then the promise is rejected.
  	// handleAs: String?
  	//		How to handle the response from the server.  Default is
  	//		'text'.  Other values are 'json', 'javascript', and 'xml'.
  });
  request.__MethodOptions = declare(null, {
  	// method: String?
  	//		The HTTP method to use to make the request.  Must be
  	//		uppercase.
  });
  request.__Options = declare([request.__BaseOptions, request.__MethodOptions]);
  	request.get = function(url, options){
  	// summary:
  	//		Send an HTTP GET request using the default transport for the current platform.
  	// url: String
  	//		URL to request
  	// options: dojo/request.__BaseOptions?
  	//		Options for the request.
  	// returns: dojo/request.__Promise
  };
  request.post = function(url, options){
  	// summary:
  	//		Send an HTTP POST request using the default transport for the current platform.
  	// url: String
  	//		URL to request
  	// options: dojo/request.__BaseOptions?
  	//		Options for the request.
  	// returns: dojo/request.__Promise
  };
  request.put = function(url, options){
  	// summary:
  	//		Send an HTTP POST request using the default transport for the current platform.
  	// url: String
  	//		URL to request
  	// options: dojo/request.__BaseOptions?
  	//		Options for the request.
  	// returns: dojo/request.__Promise
  };
  request.del = function(url, options){
  	// summary:
  	//		Send an HTTP DELETE request using the default transport for the current platform.
  	// url: String
  	//		URL to request
  	// options: dojo/request.__BaseOptions?
  	//		Options for the request.
  	// returns: dojo/request.__Promise
  };
  =====*/
  return request;
});